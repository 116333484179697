// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '../src/assets/css/base-material.css'
import '../src/assets/css/buttons-material.css'
import '../src/assets/css/calendars-material.css'
import '../src/assets/css/dropdowns-material.css'
import '../src/assets/css/grids-material.css'
import '../src/assets/css/inputs-material.css'
import '../src/assets/css/navigations-material.css'
import '../src/assets/css/popups-material.css'
import '../src/assets/css/splitbuttons-material.css'
import '../src/assets/css/frankpetzchen.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  components: { App }
}).$mount('#app')
